import {AuthContext} from "react-oauth2-code-pkce"
import React, {useContext} from "react";
import {Button} from "./Button";

export function UserInfo() {
  const {error, token, tokenData, idToken, idTokenData} = useContext(AuthContext);

  if (error)
    return <div>Error loading profile: {error}</div>;

  return <>
    <Token name="Access token" data={tokenData}>{token}</Token>
    <Token name="ID token" data={idTokenData}>{idToken}</Token>
  </>;

  function Token({name, data, children}) {
    return <div className="mb-4">
      <h4 className="font-bold">{name}</h4>
      <div className="italic my-2">{children}</div>
      <div>Decoded:
        <pre className="text-xs">{JSON.stringify(data, null, 2)}</pre>
      </div>
    </div>;
  }
}

export function LoginButton({textClass, bgClass, className = "", children}) {
  const {logIn} = useContext(AuthContext);

  return <Button onClick={() => logIn()} textClass={textClass} bgClass={bgClass} className={className}>{children}</Button>;
}