import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {GraphQlError} from "../components/GraphQLError";
import {BehaviourPoll} from "../components/BehaviourPoll";
import {useParams} from "react-router-dom";
import {allAssessmentFields} from "../components/Assessment";

const GET = gql`
    ${allAssessmentFields}
    query getAssessment($assessmentId: ID!) {
        assessment(id: $assessmentId) {
            ...AllAssessmentFields
        }
    }
`;

export function AssessmentSurveyRoute() {
    const { id } = useParams();
    const { loading, error, data } = useQuery(GET, {variables: {assessmentId: id}});

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error} />;

    if (!data.assessment.behaviourList.length)
        return <div>
            No challenges defined for this assessment.
        </div>;

    return <div className="w-full">
        {data.assessment.behaviourList.map(behaviour => <BehaviourPoll key={behaviour.id} behaviour={behaviour} className="mt-4"/>) }
    </div>
}
