import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {GraphQlError} from "../components/GraphQLError";
import {allAssessmentFields} from "../components/Assessment";
import {cc, Paragraph, Subheading} from "../components/Typography";
import {Source} from "../components/Source";
import {Button} from "../components/Button";
import {useNavigate} from "react-router-dom";

const GET = gql`
    ${allAssessmentFields}
    query {
        assessmentList {
            ...AllAssessmentFields
        }
    }
`;

export function AssessmentRoute() {
    const { loading, error, data } = useQuery(GET);

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error} />;

    return <div className="w-full">
        {data.assessmentList.map(assessment => <Assessment key={assessment.id} assessment={assessment} className="mt-4"/>) }
    </div>
}

function Assessment({assessment, className}) {
    const navigate = useNavigate();
    const handleView = () => navigate(`/assessments/${assessment.id}/result`);
    const handleSurvey = () => navigate(`/assessments/${assessment.id}/survey`);

    return <div className={cc(className)}>
        <Subheading>Name: {assessment.name}</Subheading>
        {assessment.description && <Paragraph>{assessment.description}</Paragraph>}
        <Source wrapper={s => <div className="italic text-sm text-right pt-1">source: {s}</div>}>{assessment.source}</Source>

        <div className="mt-1 grid grid- grid-cols-2 gap-1">
            <Button className="bg-soc-cheeks hover:bg-soc-cheeks-dark" onClick={handleView}>View</Button>
            <Button className="bg-soc-cheeks hover:bg-soc-cheeks-dark" onClick={handleSurvey}>Assess</Button>
        </div>
    </div>;
}