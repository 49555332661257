import React from "react";
import {cc, Emphasize, Item, List, Paragraph, Subheading} from "./Typography";

export function Instructions({title, className, children, narrow = false}) {
    return <div className={className}>
        {title && <Subheading>{title}</Subheading>}
        {children}
        <Paragraph>
            Spend no more than 5 minutes going through the following steps:
            <List ordered className={cc(narrow ? "" : "md:grid-cols-3", "grid grid-cols-1 my-4 marker:italic marker:font-medium")}>
                <Item>
                    <Emphasize>Find out if the statement applies to your team.</Emphasize>
                </Item>
                <Item>
                    <Emphasize>Reflect on its impact on your team's effectiveness</Emphasize>
                    and how your team can improve related to the statement.
                </Item>
                <Item>
                    <Emphasize>Decide what you will do today</Emphasize>
                    - and today only - to move the needle a little bit further in the right direction.
                </Item>
            </List>
        </Paragraph>
    </div>;
}