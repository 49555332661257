import React from "react";

export function PageCenter({className, children, ...props}) {
  return <div className={`${className || ''} w-screen h-screen grid place-content-center`} {...props}>
    {children}
  </div>
}

export function Center({children}) {
  return <div className="w-full place-self-center grid place-content-center">
    {children}
  </div>;
}
