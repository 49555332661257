import React from "react";
import {useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {GraphQlError} from "../components/GraphQLError";
import {BehaviourObservation, getBehaviourObservationList} from "../components/BehaviourObservation";

export function PollsRoute() {
    const { loading, error, data } = useQuery(getBehaviourObservationList);

    if (loading) return <Center><p>Loading...</p></Center>;

    if (error) return <GraphQlError error={error} />;

    if (!data.behaviourObservationList.length)
        return <div>
            No observations recorded yet.
        </div>;

    return <div className="w-full">
        {data.behaviourObservationList.map(poll => <BehaviourObservation key={`${poll.behaviour.id}-${poll.createdAt}`} observation={poll} />) }
    </div>
}