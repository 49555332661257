import React, {useEffect, useState} from "react";

export function ElementLoop({className, timeout = 1500, children}) {
    const [activeChild, setActiveChild] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveChild((activeChild + 1) % children.length)
        }, timeout);
        return () => {
            clearInterval(interval);
        };
    }, [activeChild]);

    return <span className={className}>{children[activeChild]}</span>;
}