import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {allBehaviourFields, Behaviour} from "../components/Behaviour";
import {GraphQlError} from "../components/GraphQLError";
import {cc, Emphasize, Heading, Italicize, Item, List, Paragraph, Subheading} from "../components/Typography";
import {Link} from "react-router-dom";
import {UnlessAuthenticated, WhenAuthenticated} from "../components/Authorization.helper";
import {BehaviourPoll} from "../components/BehaviourPoll";
import {Instructions} from "../components/Instructions";
import {Entry, ElementLoop} from "../components/ElementLoop";
import {LoginButton} from "../components/Authentication";

const GET_TODAYS_CHALLENGE = gql`
    ${allBehaviourFields}
    query {
        todaysChallenge {
            ...AllBehaviourFields
        }
    }
`;

export function HomeRoute() {
    const { loading, error, data } = useQuery(GET_TODAYS_CHALLENGE);

    if (loading) return <Center><p>Loading...</p></Center>;

    if (error) return <GraphQlError error={error} />;

    return <div className="w-full">
        <UnlessAuthenticated>
            <div className="flex flex-col md:flex-row md:gap-x-6">
                <div className="md:basis-2/3">
                    <DoYouRecognizeTheseChallenges/>
                    <WelcomeToSocratesAdvice />
                    <HowDoesItWork />
                    <WhatDoYouNeed />
                </div>
                <TodaysChallenge className="md:basis-1/3" behaviour={data.todaysChallenge}></TodaysChallenge>
            </div>
        </UnlessAuthenticated>
        <WhenAuthenticated>
            <WelcomeToSocratesAdvice />
            <CommonActions />
            <TodaysPoll behaviour={data.todaysChallenge}/>
        </WhenAuthenticated>
    </div>
}

function TodaysChallenge({className, behaviour}) {
    return <div className={className}>
        <Instructions title="Can't wait?" narrow><Paragraph>Try this in the meantime.</Paragraph></Instructions>
        <Behaviour className="mt-2" behaviour={behaviour}/>
    </div>;
}

function WelcomeToSocratesAdvice() {
    return <>
        <Heading>Welcome to <Emphasize>Socrates Advice</Emphasize>, your trusted guide on your way to greatness!</Heading>
        <Paragraph>
            We help all software delivery teams continuously improve so you can focus on delivering more high-quality
            services faster.
        </Paragraph>
    </>;
}

function DoYouRecognizeTheseChallenges() {
    return <>
        <Heading>Are you a <ElementLoop className="bold text-soc-cheeks-dark">
            <>Scrum Master</>
            <>Team Lead</>
            <>Flow Master</>
            <>Project Manager</>
        </ElementLoop> and recognize these challenges?</Heading>
        <Paragraph>
            <List>
                <Item>The team is working hard, but you realize there are <Emphasize>opportunities for working smarter</Emphasize></Item>
                <Item>You have <Emphasize>neither the time, nor the support</Emphasize> to analyse what's happening and coach the team</Item>
                <Item>You are responsible for <Italicize>getting things done</Italicize> and <Emphasize>focus on the team’s delivery objectives</Emphasize></Item>
                <Item>Working with the product owner, technical leadership and the team keeps you <Emphasize>very busy</Emphasize></Item>
                <Item>You feel <Emphasize>pressure to meet deadlines</Emphasize>, to fend of or integrate new requests from stakeholders and address quality issues in production</Item>
            </List>
        </Paragraph>
    </>;
}

function HowDoesItWork() {
    return <>
        <Heading>How does it work?</Heading>
        <Paragraph>
            <List ordered>
                <Item>
                    <Emphasize>You</Emphasize> provide some information so that Socrates can learn a few things about your team.
                </Item>
                <Item>
                    Then <Emphasize>Socrates</Emphasize> will tell you how your team is doing and provide you with some guidance on how to help your team get better.
                </Item>
                <Item>
                    Socrates continues to learn about your team as time goes by and help your team continuously improve.
                </Item>
            </List>
        </Paragraph>
    </>;
}

function WhatDoYouNeed() {
    return <>
        <Heading>What do you need?</Heading>
        <Paragraph>
            All you need to get started is a Google account. Easy enough right?
        </Paragraph>
        <LoginButton>Login with your Google Account</LoginButton>
    </>;
}

function CommonActions() {
    return <div className="grid grid-cols-1 md:grid-cols-3 gap-2 items-stretch">
        <Action href="/assessments/b1bef7e5-01ed-404b-98d3-5a988a617fbc/result">Check out how your team is doing</Action>
        <Action href="/assessments/b1bef7e5-01ed-404b-98d3-5a988a617fbc/survey">Take a survey</Action>
    </div>;
}

function Action({href, unavailable = false, children}) {
    return <Link to={href} className={cc(
        unavailable ? "bg-gray-100 text-gray-600" : "bg-soc-face hover:bg-soc-cheeks",
        "flex items-center justify-center text-lg text-center p-8"
    )}>
            {children}
    </Link>;
}

function TodaysPoll({behaviour}) {
    return <>
        <Heading>If nothing else, check out today's challenge</Heading>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-6">
            <BehaviourPoll className="col-span-1 md:col-span-2" behaviour={behaviour}/>
            <Instructions narrow/>
        </div>
    </>;
}