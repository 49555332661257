import {useMutation} from "@apollo/client";
import {Behaviour} from "./Behaviour";
import {Button} from "./Button";
import React from "react";
import {BehaviourObservation, createBehaviourObservation} from "./BehaviourObservation";

export function BehaviourPoll({behaviour, className}) {
    const [createObservation, {data, loading, error}] = useMutation(createBehaviourObservation);

    const recordObservation = (isPresent) => {
        console.log("Record", isPresent, behaviour);
        createObservation({variables: {behaviour: behaviour.id, isPresent: isPresent}});
    }

    if (loading) return 'Submitting';
    if (error) return `Submission error! ${error.message}`;
    if (data) return <BehaviourObservation observation={data.createBehaviourObservation}/>;

    return <div className={className}>
        <Behaviour behaviour={behaviour}/>

        <ExpandedButtonPanel onYes={() => recordObservation(true)} onNo={() => recordObservation(false)} />
    </div>;
}

function ExpandedButtonPanel({onYes, onNo}) {
    return <div className="mt-1 grid grid- grid-cols-2 gap-1">
        <Button bgClass="bg-green-500 hover:bg-green-600" onClick={onYes}>Yes, this is true
            for us</Button>
        <Button bgClass="bg-red-500 hover:bg-red-600" onClick={onNo}>No, this is not the
            case for us</Button>
    </div>;
}

