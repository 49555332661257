import React from "react";

export function Footer() {
    return <div className="flex justify-between gap-x-2 text-sm mt-2">
        <div>
            &copy; MMXXIV Socrates Advice Inc. - version {process.env.REACT_APP_VERSION}
        </div>
        {/*<div className="text-right">*/}
        {/*    Friends of <a href={"https://xodiac.ca"}>xodiac.ca</a>*/}
        {/*</div>*/}
    </div>
}
