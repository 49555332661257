import React, {useContext} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {Button} from "../components/Button";
import {useNavigate} from "react-router-dom";
import {UserInfo} from "../components/Authentication";
import {WhenAuthorized} from "../components/Authorization.helper";

export function Profile() {
  const {logOut, idTokenData} = useContext(AuthContext);
  const navigate = useNavigate();

  function logoutAndGoHome() {
    logOut();
    navigate("/");
  }

  if (idTokenData)
    return <div>
      <Button onClick={() => logoutAndGoHome()}>Logout</Button>
      <WhenAuthorized role="admin"><UserInfo /></WhenAuthorized>
    </div>;

  return <div>User is not logged in.</div>
}
