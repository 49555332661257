import {allBehaviourFields, Behaviour} from "./Behaviour";
import {Paragraph} from "./Typography";
import React from "react";
import {gql} from "@apollo/client";
import {Timestamp} from "./Timestamp";

export const allBehaviourObservationFields = gql`
    ${allBehaviourFields}
    fragment AllBehaviourObservationFields on BehaviourObservation {
        behaviour {
            ...AllBehaviourFields
        }
        user {
            id
        }
        isPresent
        createdAt
    }
`;

export const getBehaviourObservationList = gql`
    ${allBehaviourObservationFields}
    query {
        behaviourObservationList {
            ...AllBehaviourObservationFields
        }
    }
`;

export const createBehaviourObservation = gql`
    ${allBehaviourObservationFields}
    mutation CreateBehaviourObservation($behaviour: ID!, $isPresent: Boolean!) {
        createBehaviourObservation(id: $behaviour, isPresent: $isPresent) {
            ...AllBehaviourObservationFields
        }
    }
`;


export function BehaviourObservation({observation}) {
    return <div className="my-1">
        <Behaviour behaviour={observation.behaviour}/>
        <Paragraph className="text-right">
            {observation.user.id} said this was <span
            className="font-bold">{observation.isPresent ? "true" : "not true"}</span> on <Timestamp ts={observation.createdAt}/>
        </Paragraph>
    </div>;
}

