import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmarkLarge} from "@fortawesome/pro-solid-svg-icons";
import {Link, NavLink} from "react-router-dom";
import {Logo} from "./Logo";
import {AuthContext} from "react-oauth2-code-pkce";
import {LoginButton} from "./Authentication";
import {cc} from "./Typography";

export function Header({className = ""}) {
    return <nav className={cc(className, "flex justify-between")}>
        <Link to="/">
            <Logo className="h-10"><span className="ml-3 font-title text-2xl content-center">Socrates</span></Logo>
        </Link>
        <MenuBar>
            <MenuItem href="/assessments" requiresLogin>Assessment</MenuItem>
            <MenuItem href="/polls" requiresLogin>Polls</MenuItem>
            {/*<MenuItem href="/teams" requiresLogin>Teams</MenuItem>*/}
            {/*<MenuItem href="/model" requiresLogin>Model</MenuItem>*/}
            <ProfileMenuItem href="/profile">Login</ProfileMenuItem>
        </MenuBar>
    </nav>;
}


function MenuBar({children}) {
    const [mobileView, setMobileView] = useState(false);

    return <>
        <div className="w-full md:items-center md:w-auto">
            <ul className={cc(
                mobileView ? "" : "hidden",
                "flex font-medium px-0 rtl:space-x-reverse",
                "flex-col absolute top-24 inset-x-0 bg-soc-face",
                "md:inline-flex md:flex-row md:flex-grow md:relative md:top-0 md:bg-inherit"
            )}>
                {children}
            </ul>
        </div>
        <button type="button"
                className={cc(
                    mobileView ? "bg-soc-cheeks" : "",
                    "flex-none w-10 h-10 mb-1 rounded-md hover:bg-soc-cheeks-dark md:hidden"
                    )}
                aria-controls="navbar-default" aria-expanded="false" onClick={() => setMobileView(!mobileView)}>
            <span className="sr-only">Open main menu</span>
            <FontAwesomeIcon icon={mobileView ? faXmarkLarge : faBars}/>
        </button>
    </>;
}

function MenuItem({href, className = "", requiresRole, requiresLogin = false, isImage = false, children}) {
    const {tokenData} = useContext(AuthContext);

    if (requiresLogin && !tokenData) return;

    return <li>
        <NavLink to={href}
                 aria-current="page"
                 className={({isActive}) => cc(
                         isImage ? "pl-6 md:pl-0" : "px-6 py-2",
                         getMenuItemStyle(isActive),
                         className
                 )}>
            {children}
        </NavLink>
    </li>;
}

function LoginMenuItem({children}) {
    return <li className={getMenuItemStyle()}>
        <LoginButton textClass="text-black" bgClass="bg-transparent hover:bg-transparent">{children}</LoginButton>
    </li>
}

function getMenuItemStyle(active = false) {
    return cc(
        active ? "border-soc-cheeks-dark font-bold" : "border-transparent",
        "menu-item block",
        "hover:border-soc-cheeks border-l-8 md:border-l-0 md:border-b-4"
    );
}

function ProfileMenuItem({href, children}) {
    const {idTokenData} = useContext(AuthContext);

    if (idTokenData)
        return <MenuItem href={href} className="md:ml-2" isImage><ProfileIcon idTokenData={idTokenData} /></MenuItem>;
    return <LoginMenuItem>{children}</LoginMenuItem>;
}

function ProfileIcon({idTokenData, hoverClassName = ""}) {
    return <>
        <img src={idTokenData.picture} className={cc(hoverClassName, "rounded-sm h-10 w-10 inline-block")} />
        <span className="md:hidden ml-2">{idTokenData.name}</span>
    </>;
}
