import {createBrowserRouter, Outlet, useRouteError} from "react-router-dom";
import {App} from "./App";
import {Error} from "./components/Error";
import {HomeRoute} from "./routes/Home.route";
import {Profile} from "./routes/Profile";
import {Model} from "./components/Model";
import {Center} from "./components/Center";
import {AuthContext} from "react-oauth2-code-pkce";
import {Button} from "./components/Button";
import {useContext} from "react";
import {PollsRoute} from "./routes/Polls.route";
import {AssessmentSurveyRoute} from "./routes/AssessmentSurvey.route";
import {AssessmentResultRoute} from "./routes/AssessmentResult.route";
import {AssessmentRoute} from "./routes/Assessment.route";

export const routerConfig = createBrowserRouter([{
    path: "/",
    element: <App />,
    errorElement: <App><Center><RouteError/></Center></App>,
    children: [
      { index: true, element: <HomeRoute/> },
      {
        element: <ProtectedRoute/>,
        errorElement: <Center><RouteError/></Center>,
        children: [
          { path: "profile", element: <Profile/> },
          { path: "model", element: <Model/> },
          { path: "polls", element: <PollsRoute/> },
          { path: "assessments", element: <AssessmentRoute/> },
          { path: "assessments/:id/survey", element: <AssessmentSurveyRoute/> },
          { path: "assessments/:id/result", element: <AssessmentResultRoute/> }
        ]
      }
    ]
  }]);

function ProtectedRoute() {
  const {idTokenData, logIn} = useContext(AuthContext);

  if (idTokenData) return <Outlet />;

  return <div>
    <div>You are requesting protected information, please login.</div>
    <Button className="mt-1" onClick={() => logIn()}>Login</Button>
  </div>;
}

function RouteError() {
  const error = useRouteError();
  return <Error status={error.status}>{error.statusText || error.message}</Error>;
}
