import React from "react";
import {Link} from "react-router-dom";
import {cc} from "./Typography";
import {gql} from "@apollo/client";
import {Source} from "./Source";

export const allBehaviourFields = gql`
  fragment AllBehaviourFields on Behaviour {
    id
    summary
    source
  }
`;

export const getBehaviourList = gql`
  ${allBehaviourFields}
  query {
    behaviourList {
      ...AllBehaviourFields
    }
  }
`;

export function Behaviour({behaviour, className}) {
  return <div className={cc(className, "border-soc-cheeks border-2 rounded-md p-4 text-xl")}>
    {behaviour.summary}
    <Source wrapper={s => <div className="italic text-sm text-right pt-1">source: {s}</div>}>{behaviour.source}</Source>
  </div>;
}