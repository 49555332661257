import {Footer} from "./components/Footer";
import {Header} from "./components/Header";

import {Outlet} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonDigging} from "@fortawesome/pro-solid-svg-icons";

export function App({children}) {
  return (
      <div className="min-h-screen flex flex-col">
        <EnvironmentBanner environment={process.env.NODE_ENV} />
        <div className="flex flex-col p-2 min-h-max flex-grow">
          <Header className="mb-2 mx-1 md:mx-4"/>
          <div className="grow flex mx-1 md:mx-4">
            <Outlet/>
            {children}
          </div>
          <footer className="flex-none mx-1 md:mx-4 mt-4">
            <Footer/>
          </footer>
        </div>
      </div>
  );
}

function EnvironmentBanner({environment}) {
  if (environment !== "development") return <></>;
  return <div className="bg-black text-white p-2 text-center italic"><FontAwesomeIcon icon={faPersonDigging}/> This is a development environment</div>;
}
