import {AuthContext} from "react-oauth2-code-pkce";
import {StatusCode} from "status-code-enum";
import {Center} from "./Center";
import {Error} from "./Error";
import {useContext} from "react";

export function GraphQlError({error}) {
  const {logIn} = useContext(AuthContext);

  if (error.networkError?.statusCode === StatusCode.ClientErrorUnauthorized) {
    logIn();
  }

  return <Center><Error>
    <div>{error.message}</div>
    <div className="text-xs">{JSON.stringify(error)}</div>
  </Error></Center>;
}
