import {debug} from "./services/Debugger";

export const authConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  authorizationEndpoint: `${process.env.REACT_APP_AUTH_SERVER_URL}${process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT}`,
  tokenEndpoint: `${process.env.REACT_APP_AUTH_SERVER_URL}${process.env.REACT_APP_AUTH_TOKEN_ENDPOINT}`,
  redirectUri: window.location.origin,
  scope: 'profile openid',
  autoLogin: false,
  onRefreshTokenExpire: (event) => {
    debug("Refreshing login at", window.location.origin, event);
    event.logIn();
  },
  preLogin: () => {
    authConfig.redirectUri = window.location.origin;
    debug("Calling pre-login redirecting to:", authConfig.redirectUri);
  }
}