import {Link} from "react-router-dom";
import React from "react";

export function Source({children, wrapper}) {
    if (!children) return;

    if (wrapper) return wrapper(getContent(children));
    return getContent(children);

    function getContent(source) {
        try {
            new URL(source); // throws exception when not a URL
            return <Link to={source}>{source}</Link>
        } catch (_) {
            return source;
        }
    }
}